<template>
  <RMenu v-model="isDropdownVisible">
    <template v-slot:activator>
      <div
        class="flex flex-row items-center space-x-2 text-lg cursor-pointer hover:text-gray-500"
        @click="isDropdownVisible = !isDropdownVisible"
      >
        <span>{{ title }}</span>
        <i class="fas fa-caret-down"></i>
      </div>
    </template>
    <div class="flex flex-col py-4 w-96">
      <h1 class="font-bold px-4 pb-2">Pages:</h1>
      <PageSelectorItem
        v-for="page in pages"
        :key="page.slug"
        :to="{ name: 'PageEditor', params: { slug: page.slug } }"
        :avatarUrl="page.data.header.avatarUrl"
        :title="page.data.header.title"
        @select="isDropdownVisible = false"
      />
      <hr />
      <PageSelectorItem
        :to="{ name: 'PageCreator' }"
        icon="fas fa-plus"
        title="Create a new page"
        @select="isDropdownVisible = false"
      />
    </div>
  </RMenu>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import useAllUserPagesFetcher from "@/modules/admin/compositions/useAllUserPagesFetcher";
import PageSelectorItem from "@/modules/admin/components/PageSelectorItem";
import RMenu from "@/modules/core/components/RMenu";

export default {
  name: "PageSelecor",
  components: {
    RMenu,
    PageSelectorItem
  },
  setup() {
    const { pages, fetchAllPages } = useAllUserPagesFetcher();
    const route = useRoute();
    const title = ref(null);
    const isDropdownVisible = ref(false);

    onMounted(async () => {
      await fetchAllPages();
      reloadSelector();
    });

    watch(
      () => route.params,
      () => reloadSelector()
    );

    const reloadSelector = () => {
      if (route.name == "PageCreator") {
        title.value = "Untitled page";
        return;
      }

      const currentSlug = route.params.slug;
      const hasPageWithSlug =
        pages.value?.some(p => p.slug == currentSlug) ?? false;

      title.value = hasPageWithSlug ? currentSlug : null;
    };

    return { isDropdownVisible, pages, title };
  }
};
</script>
