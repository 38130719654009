<template>
  <div class="phone">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PhoneFrame"
};
</script>

<style scoped>
.phone {
  height: 100%;
  border: 1.334vh solid #111;
  border-radius: 4.5vh;
  overflow: hidden;
}
</style>
