import { ref, readonly } from "vue";
import useUserPages from "@/modules/admin/compositions/useUserPages";

export default function useAllUserPagesFetcher() {
  const { fetchAllPages: fetchAllUserPages } = useUserPages();
  const pages = ref(null);
  const error = ref(null);
  const isLoading = ref(false);

  const fetchAllPages = async () => {
    isLoading.value = true;
    error.value = null;

    try {
      pages.value = await fetchAllUserPages();
    } catch (err) {
      error.value = err;
    }

    isLoading.value = false;
  };

  return {
    pages: readonly(pages),
    error: readonly(error),
    isLoading: readonly(isLoading),
    fetchAllPages
  };
}
