import { ref, readonly } from "vue";
import useUserPages from "@/modules/admin/compositions/useUserPages";

export default function userUserPageRemover() {
  const { deletePage } = useUserPages();
  const error = ref(null);
  const isLoading = ref(false);

  const removePage = async slug => {
    isLoading.value = true;
    error.value = null;

    try {
      await deletePage(slug);
    } catch (err) {
      error.value = err;
    }

    isLoading.value = false;
  };

  return {
    error: readonly(error),
    isLoading: readonly(isLoading),
    removePage
  };
}
