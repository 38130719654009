<template>
  <div
    class="flex flex-col items-center space-y-4 p-4 bg-white rounded-lg shadow-xl"
  >
    <div class="flex flex-row items-center w-full h-16 space-x-4">
      <slot name="header" />
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "Card"
};
</script>
