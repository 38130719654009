<template>
  <div id="jsoneditor"></div>
</template>

<script>
import { reactive, computed, onMounted } from "vue";
import JSONEditor from "jsoneditor/dist/jsoneditor.min.js";
import "jsoneditor/dist/jsoneditor.min.css";

export default {
  name: "JsonEditor",
  props: {
    modelValue: {
      type: Object,
      required: false
    }
  },
  emits: ["update:modelValue", "error"],
  setup(props, { emit }) {
    let editor = reactive({});
    const json = computed({
      get: () => props.modelValue,
      set: value => emit("update:modelValue", value)
    });

    onMounted(() => {
      const reference = document.getElementById("jsoneditor");
      const options = {
        escapeUnicode: false,
        history: true,
        indentation: 2,
        mode: "tree",
        modes: ["tree", "code"],
        navigationBar: true,
        search: true,
        statusBar: true,
        sortObjectKeys: false,
        onChange: () => {
          try {
            json.value = editor.get();
          } catch (err) {
            emit("error", err);
          }
        }
      };

      editor = new JSONEditor(reference, options, json.value);
    });
  }
};
</script>
