<template>
  <AsyncContainer>
    <Editor :key="route.params.slug" :slug="route.params.slug" />
  </AsyncContainer>
</template>

<script>
import { useRoute } from "vue-router";
import AsyncContainer from "@/modules/core/components/AsyncContainer";
import Editor from "@/modules/admin/fragments/Editor";

export default {
  name: "PageEditor",
  components: {
    AsyncContainer,
    Editor
  },
  setup() {
    const route = useRoute();

    return { route };
  }
};
</script>
