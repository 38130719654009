<template>
  <div class="flex flex-col w-96 p-8 space-y-16 bg-white rounded-lg shadow-xl">
    <h1 class="font-bold text-3xl text-gray-800">Create new page</h1>
    <RForm class="space-y-4" @submit="create">
      <RTextfield
        type="text"
        label="Page name"
        v-model="pageCreationData.name"
        :disabled="isLoading"
      />
      <RTextfield
        type="text"
        label="Page slug"
        v-model="pageCreationData.slug"
        :disabled="isLoading"
        :rules="[required]"
      />
      <label
        v-if="error"
        class="block text-center text-red-500 text-lg font-bold"
      >
        {{ error.message }}
      </label>
      <div class="py-4">
        <RButton
          class="w-full"
          type="submit"
          :loading="isLoading"
          :disabled="!canCreate"
        >
          <span>Create</span>
        </RButton>
      </div>
    </RForm>
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { useRouter } from "vue-router";
import useUserPageCreator from "@/modules/admin/compositions/useUserPageCreator";
import useValidation from "@/modules/core/compositions/useValidation.js";
import RForm from "@/modules/core/components/RForm";
import RTextfield from "@/modules/core/components/RTextField";
import RButton from "@/modules/core/components/RButton";

export default {
  name: "PageCreator",
  components: {
    RForm,
    RTextfield,
    RButton
  },
  setup() {
    const { isLoading, error, createdPage, createPage } = useUserPageCreator();
    const { required } = useValidation();
    const router = useRouter();
    const pageCreationData = reactive({
      name: null,
      slug: null
    });

    const canCreate = computed(() => {
      return !!pageCreationData.name && !!pageCreationData.slug;
    });

    const create = async () => {
      await createPage(pageCreationData);
      if (createdPage.value && !error.value) {
        router.push({
          name: "PageEditor",
          params: { slug: createdPage.value.slug }
        });
      }
    };

    return { pageCreationData, isLoading, error, canCreate, create, required };
  }
};
</script>
