<template>
  <nav
    class="flex flex-row items-center w-full h-20 py-8 px-4 space-x-4 border-b border-gray-300"
  >
    <section class="flex flex-row items-center space-x-2 mr-auto">
      <router-link
        :to="{ name: 'Admin' }"
        class="flex flex-row items-center space-x-2"
      >
        <RIcon size="fa-2x" name="fas fa-satellite text-gray-800" />
        <span class=" text-2xl text-black">Relay</span>
      </router-link>
      <span class="text-2xl text-gray-200">/</span>
      <PageSelector />
    </section>
    <section class="ml-auto"></section>
    <RButton color="danger" size="sm" :loading="isLoading" @click="logout">
      <span>Sign out</span>
    </RButton>
  </nav>
</template>

<script>
import useAuth from "@/modules/auth/compositions/useAuth.js";
import { useRouter } from "vue-router";
import RIcon from "@/modules/core/components/RIcon";
import RButton from "@/modules/core/components/RButton";
import PageSelector from "@/modules/admin/fragments/PageSelector";

export default {
  name: "Navbar",
  components: {
    RButton,
    RIcon,
    PageSelector
  },
  setup() {
    const { isLoading, signOut } = useAuth();
    const router = useRouter();

    const logout = async () => {
      await signOut();
      router.push({ name: "Login" });
    };

    return { isLoading, logout };
  }
};
</script>
