<template>
  <div class="flex flex-row w-full h-full space-x-8 px-4 overflow-hidden">
    <RCard class="editor-card">
      <template v-slot:header>
        <h1 class="text-2xl font-medium mr-auto">
          {{ pageData.header.title }}
        </h1>
        <PageOptions class="ml-auto" :page="page" />
        <RButton
          color="success"
          size="sm"
          :loading="isLoading"
          :disabled="!canUpdagePage"
          @click="updateUserPage"
        >
          <span>Update</span>
        </RButton>
      </template>
      <JsonEditor v-model="pageData" class="h-full w-full overflow-y-auto" />
    </RCard>
    <RCard class="preview-card">
      <template v-slot:header>
        <h1 class="text-2xl font-medium mr-auto">
          Preview
        </h1>
        <RButton class="ml-auto" color="primary" size="sm" @click="visitPage">
          <span>Visit</span>
        </RButton>
      </template>
      <div class="flex items-center justify-center w-full h-full">
        <PhoneFrame class="iPhone-frame">
          <PageFragment class="px-2 py-8 overflow-y-scroll" :page="pageData" />
        </PhoneFrame>
      </div>
    </RCard>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import useUserPageFetcher from "@/modules/admin/compositions/useUserPageFetcher";
import useUserPageUpdater from "@/modules/admin/compositions/useUserPageUpdater";
import JsonEditor from "@/modules/admin/components/JsonEditor";
import PageFragment from "@/modules/pages/fragments/PageFragment";
import PhoneFrame from "@/modules/admin/components/PhoneFrame";
import PageOptions from "@/modules/admin/fragments/PageOptions";
import RCard from "@/modules/core/components/RCard";
import RButton from "@/modules/core/components/RButton";

export default {
  name: "Editor",
  components: {
    JsonEditor,
    PageFragment,
    PhoneFrame,
    PageOptions,
    RCard,
    RButton
  },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  async setup(props) {
    const { page, fetchPage } = useUserPageFetcher();
    const { isLoading, updatePage } = useUserPageUpdater();
    const pageData = ref(null);
    const canUpdagePage = ref(false);

    watch(pageData, currentData => {
      canUpdagePage.value =
        JSON.stringify(currentData) != JSON.stringify(page.value.data);
    });

    await fetchPage(props.slug);
    pageData.value = page.value.data;

    const updateUserPage = async () => {
      const updatedPage = {
        slug: page.value.slug,
        data: pageData.value
      };
      await updatePage(updatedPage);
    };

    const visitPage = () => {
      window.open(`/${page.value.slug}`, "_blank");
    };

    return {
      page,
      pageData,
      isLoading,
      updateUserPage,
      canUpdagePage,
      visitPage
    };
  }
};
</script>
<style scoped>
.card {
  @apply my-4;
}

.editor-card {
  @apply flex-1 card;
}

.preview-card {
  @apply card hidden lg:flex;
  width: 480px;
}

.iPhone-frame {
  @apply transform scale-75 xl:scale-100;
  width: 375px;
  max-height: 812px;
  height: 812px;
}
</style>
