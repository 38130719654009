<template>
  <div>
    <div ref="activator">
      <slot name="activator" />
    </div>
    <div
      v-if="modelValue"
      role="menu"
      ref="menu"
      class="bg-white absolute z-10 rounded-lg elevation-10"
      :style="styleList"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";

export default {
  name: "RMenu",
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false
    },
    closeOnOutsideClick: {
      type: Boolean,
      required: false,
      default: true
    },
    closeOnContentClick: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const activator = ref(null);
    const menu = ref(null);
    const isActive = computed({
      get: () => props.modelValue,
      set: value => emit("update:modelValue", value)
    });

    const activatorEdges = computed(() => {
      return {
        top: activator.value.offsetTop,
        right: activator.value.offsetLeft + activator.value.clientWidth,
        bottom: activator.value.offsetTop + activator.value.clientHeight,
        left: activator.value.offsetLeft
      };
    });

    const styleList = computed(() => {
      return {
        left: `${activatorEdges.value.left}px`,
        top: `${activatorEdges.value.bottom}px`
      };
    });

    watch(isActive, () => {
      setTimeout(() => {
        if (isActive.value) {
          document.addEventListener("click", clickHandler);
        } else {
          document.removeEventListener("click", clickHandler);
        }
      });
    });

    const clickHandler = event => {
      const didClickInsideMenu = event.composedPath().includes(menu.value);

      if (didClickInsideMenu) {
        isActive.value = props.closeOnContentClick ? false : true;
      } else {
        isActive.value = props.closeOnOutsideClick ? false : true;
      }
    };

    return { activator, menu, styleList };
  }
};
</script>
