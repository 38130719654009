<template>
  <div>
    <RMenu v-model="isDropdownVisible">
      <template v-slot:activator>
        <RButton
          size="sm"
          color="neutral"
          @click="isDropdownVisible = !isDropdownVisible"
        >
          <RIcon name="fas fa-ellipsis-v" size="xs" />
        </RButton>
      </template>
      <div class="flex flex-col py-4 w-48">
        <div
          class="p-4 cursor-pointer hover:bg-red-500 hover:text-white"
          @click="isRemoveDialogVisible = !isRemoveDialogVisible"
        >
          <span>Delete page</span>
        </div>
      </div>
    </RMenu>
    <teleport to="body">
      <RDialog v-if="isRemoveDialogVisible">
        <template v-slot:header>
          <p class="font-bold text-lg text-red-500">Delete confirmation</p>
        </template>
        <div>
          <p>
            Do you really want to remove the {{ page.data?.header?.title }}?
          </p>
        </div>
        <template v-slot:footer>
          <RButton
            color="neutral"
            size="sm"
            @click="isRemoveDialogVisible = !isRemoveDialogVisible"
          >
            Cancel
          </RButton>
          <RButton
            color="danger"
            size="sm"
            :loading="loading"
            @click="deletePage"
          >
            Delete
          </RButton>
        </template>
      </RDialog>
    </teleport>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import userUserPageRemover from "@/modules/admin/compositions/userUserPageRemover";
import RMenu from "@/modules/core/components/RMenu";
import RButton from "@/modules/core/components/RButton";
import RIcon from "@/modules/core/components/RIcon";
import RDialog from "@/modules/core/components/RDialog";

export default {
  name: "PageOptions",
  components: {
    RMenu,
    RButton,
    RIcon,
    RDialog
  },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { removePage, loading, error } = userUserPageRemover();
    const router = useRouter();
    const isDropdownVisible = ref(false);
    const isRemoveDialogVisible = ref(false);

    const deletePage = async () => {
      await removePage(props.page.slug);

      isDropdownVisible.value = false;

      if (!error.value) {
        router.push({ name: "Admin" });
      }
    };

    return { isDropdownVisible, isRemoveDialogVisible, loading, deletePage };
  }
};
</script>
