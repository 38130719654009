<template>
  <div
    class="flex items-center justify-center absolute inset-0 w-screen h-screen z-40"
  >
    <div class="fixed inset-0 bg-black bg-opacity-50"></div>
    <div
      class="flex flex-col space-y-8 p-4 w-96 bg-white rounded-lg elevaton-5 z-50"
    >
      <div class="flex flex-row justify-start items-center space-x-4 w-full">
        <slot name="header" />
      </div>
      <slot />
      <div class="flex flex-row justify-end items-center space-x-4 w-full">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RDialog"
};
</script>
