<template>
  <Suspense timeout="0">
    <template #default>
      <slot />
    </template>
    <template #fallback>
      <SuspenseLoadingIndicator />
    </template>
  </Suspense>
</template>

<script>
import { onErrorCaptured } from "vue";
import SuspenseLoadingIndicator from "@/modules/core/components/SuspenseLoadingIndicator";

export default {
  name: "AsyncContainer",
  components: {
    SuspenseLoadingIndicator
  },
  setup() {
    onErrorCaptured(() => {
      // TODO: Show an error
      return true;
    });
  }
};
</script>
