<template>
  <FillLayout justify="start" class="max-h-screen">
    <Navbar />
    <FillLayout bgColor="bg-gray-100" justify="start">
      <router-view />
    </FillLayout>
  </FillLayout>
</template>

<script>
import { onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import useAllUserPagesFetcher from "@/modules/admin/compositions/useAllUserPagesFetcher";
import Navbar from "@/modules/admin/fragments/Navbar";
import FillLayout from "@/modules/core/layouts/FillLayout";

export default {
  name: "AdminDashboard",
  components: {
    Navbar,
    FillLayout
  },
  async setup() {
    const { pages, fetchAllPages } = useAllUserPagesFetcher();
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      verifyRoute();
    });

    watch(
      () => route.params,
      () => verifyRoute()
    );

    const verifyRoute = async () => {
      if (route.name != "PageCreator" && route.params.slug == null) {
        await fetchAllPages();

        if (pages.value.length === 0) {
          router.push({ name: "PageCreator" });
          return;
        }

        const slugOfFirstPage = pages.value[0].slug;
        router.push({ name: "PageEditor", params: { slug: slugOfFirstPage } });
      }
    };
  }
};
</script>
