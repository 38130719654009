import { ref, readonly } from "vue";
import useUserPages from "@/modules/admin/compositions/useUserPages";

export default function useUserPageCreator() {
  const { addPage } = useUserPages();
  const createdPage = ref(null);
  const error = ref(null);
  const isLoading = ref(false);

  const createPage = async pageCreationData => {
    isLoading.value = true;
    error.value = null;

    try {
      createdPage.value = await addPage(pageCreationData);
    } catch (err) {
      error.value = err;
    }

    isLoading.value = false;
  };

  return {
    createdPage: readonly(createdPage),
    error: readonly(error),
    isLoading: readonly(isLoading),
    createPage
  };
}
