<template>
  <router-link class="text-sm text-gray-800 hover:bg-gray-100 p-4" :to="to">
    <div
      class="flex flex-row space-x-2 items-center text-gray-600"
      @click="$emit('select')"
    >
      <RIcon v-if="icon" :name="icon" />
      <RAvatar v-else size="6">
        <img v-if="avatarUrl" :src="avatarUrl" :alt="title" />
        <RIcon v-else size="fa-1x" name="fas fa-user" />
      </RAvatar>
      <span>{{ title }}</span>
    </div>
  </router-link>
</template>

<script>
import RIcon from "@/modules/core/components/RIcon";
import RAvatar from "@/modules/core/components/RAvatar";

export default {
  name: "PageSelectorItem",
  components: {
    RIcon,
    RAvatar
  },
  props: {
    to: {
      type: [Object, String],
      required: false,
      deafult: null
    },
    icon: {
      type: String,
      required: false,
      deafult: null
    },
    avatarUrl: {
      type: String,
      required: false,
      deafult: null
    },
    title: {
      type: String,
      required: false,
      deafult: null
    }
  },
  emits: ["select"]
};
</script>
